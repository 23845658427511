:root {
    --primary: #1454D6;
    --primary-rgb: 20, 84, 214;
    --dark: #2b3649;
    --dark-rgb: 43, 54, 73;
    --success: #21bb36;
    --success-rgb: 34, 192, 55;
    --medium: #8f8f99;
    --light: #E8EAEC;
    --light-rgb: 232, 234, 236;
    --font-size: 1rem;
}

*,
*::after,
*::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    color: var(--dark);
    line-height: 1.5;
}

main {
    padding: 30px 0;
    min-height: calc(100vh - 305px);
    /* max-width: 1280px; */
    /* margin: 0 auto; */
}

.container {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 15px;
}

a {
    text-decoration: none;
    color: var(--primary);
    border-bottom: 2px dotted transparent;
}

a:active,
a:focus {
    border-bottom: 2px dotted;
    outline: 0 none
}

hr {
    border: 0;
    border-bottom: 1px solid #ccc;
}

/* utilities */
.d-flex {
    display: flex;
}

.d-inline-flex {
    display: inline-flex;
}

.jc-between {
    justify-content: space-between;
}

.jc-center {
    justify-content: center;
}

.ai-center {
    align-items: center;
}

.fw-bold {
    font-weight: bold;
}

.p-0 {
    padding: 0;
}

.m-0 {
    margin: 0;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.light-text {
    color: #fff;
}

.text-primary {
    color: var(--primary);
}

.text-success {
    color: var(--success);
}

.card {
    background-color: #fff;
    border: 1px solid rgba(var(--dark-rgb), 0.2);
    border-radius: 12px;
}

.card-content {
    padding: 1rem;
}

.field-group {
    margin-bottom: 15px;
}

.multi-field-group .field-group {
    flex: 1;
}

.multi-field-group .field-group+.field-group {
    margin-left: 10px;
}

.field-group label {
    padding-bottom: 8px;
    display: block;
}

.field-input {
    font-size: 0.875rem;
    font-family: 'Roboto', sans-serif;
    border: 1px solid rgba(var(--dark-rgb), 0.4);
    border-radius: 20px;
    width: 100%;
    height: 38px;
    padding: 8px 20px;
    -webkit-transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;
    transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;
}

.field-input:focus {
    outline: 0 none;
    border-color: var(--primary);
    box-shadow: 0 0 0 1px var(--primary);
}

textarea.field-input {
    height: auto;
    min-height: 100px;
    min-width: 60%;
    max-width: 100%;
}

.field-label-check {
    margin: 15px 0;
    display: block;
    padding-left: 24px;
    position: relative;
}

.field-check {
    position: absolute;
    left: 0;
    top: 1px;
}

.btn {
    font-size: 0.875rem;
    font-family: 'Roboto', sans-serif;
    background-color: var(--light);
    color: var(--dark);
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 20px;
    font-weight: bold;
    height: 38px;
    padding: 8px 20px;
    -webkit-transition: background-color 0.2s ease-out, border-color 0.2s ease-out, box-shadow 0.2s ease-out, color 0.2s ease-out;
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out, box-shadow 0.2s ease-out, color 0.2s ease-out;
}

.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
    color: #ffffff;
}

.btn:focus,
.btn:active {
    outline: 0 none;
    box-shadow: 0 0 0 3px rgba(var(--light-rgb), 0.2);
    background-color: rgba(var(--light-rgb), 0.8);
}

.btn:hover {
    background-color: #fff;
    color: var(--dark);
    box-shadow: 0 0 0 3px rgba(var(--light-rgb), 0.8);
}

.btn-primary:hover {
    background-color: rgba(var(--primary-rgb), 0.9);
    color: #ffffff;
    box-shadow: 0 0 0 3px rgba(var(--primary-rgb), 0.2);
}

.btn-primary:focus,
.btn-primary:active {
    outline: 0 none;
    box-shadow: 0 0 0 3px rgba(var(--primary-rgb), 0.1);
    background-color: rgba(var(--primary-rgb), 0.9);
}

.btn-clear {
    background: transparent;
    box-shadow: none;
    padding: 8px 0;
}

.btn-clear:hover,
.btn-clear:focus,
.btn-clear:active {
    background: transparent;
    box-shadow: none;
}

.btn.has-icon {
    font-weight: normal;
}

.btn.has-icon.color-medium {
    color: var(--medium);
}

.btn.has-icon:hover {
    color: var(--dark);
}

.btn-dark-o {
    background-color: #ffffff;
    border-color: var(--dark);
    color: var(--dark);
}

.has-icon svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
}

header {
    padding: 0 15px;
    background-color: #fff;
    border-bottom: 1px solid rgba(var(--dark-rgb), 0.2)
}

header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.brand-logo img {
    display: block;
}

.main-nav {
    display: flex;
    align-items: center;
}

.main-nav .nav-link {
    padding: 0.5rem 1rem;
    color: var(--dark);
    font-weight: bold;
    transition: background-color 0.2s ease, color 0.2s ease;
    border: 0;
}

.main-nav .nav-link:hover {
    color: var(--primary);
}

.main-nav .nav-link:active,
.main-nav .nav-link:focus {
    border-bottom: 0;
    outline: 0 none;
}

.main-nav .nav-link.active {
    color: var(--primary);
    position: relative;
}

.main-nav .nav-link.active:after {
    content: "";
    margin: auto;
    width: 40px;
    height: 2px;
    border-radius: 2px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary);
}

.main-nav .nav-cta {
    box-shadow: 0 0 0 2px var(--primary) inset;
    border-radius: 2px;
    color: var(--primary);
    display: block;
    margin: 8px 0;
}

.main-nav .nav-cta:hover {
    background-color: var(--primary);
    color: #fff
}


.filters {
    display: flex;
    border-radius: 25px;
    border: 1px solid rgba(var(--dark-rgb), 0.2);
    padding: 0 36px;
}

.search-filter {
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;
}

.search-filter svg {
    position: absolute;
    left: 0;
    top: 17px;
}

.search-input {
    border: 1px solid transparent;
    height: 50px;
    padding: 0 24px;
    width: 100%;
}

.search-input:focus {
    outline: 0 none;
    border-bottom-color: var(--primary);
    box-shadow: 0 1px 0 0 var(--primary);
}

.filter-option {
    padding: 4px 15px;
}

.filter-option label {
    opacity: 0.7;
    font-size: 12px;
}

.filter-option select {
    display: block;
    border: 1px solid transparent;
    padding: 2px 0;
}

.divider-dot {
    width: 2px;
    height: 2px;
    border-radius: 3px;
    display: inline-block;
    background-color: rgba(var(--dark-rgb), 0.7);
    margin: 0 8px;
}

.deals-grid {
    margin: 30px 0;
    display: flex;
    flex-flow: row wrap;
}

.deal-item {
    display: flex;
    padding: 15px;
    max-width: 100%;
    flex: 1 0 100%;
    border-radius: 12px;
    cursor: pointer;
    transition: box-shadow 0.2s ease-out, background-color 0.2s ease-out;
}

.deal-item:hover {
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(var(--dark-rgb), 0.1), 0 2px 4px rgba(var(--dark-rgb), 0.2), 0 6px 12px rgba(var(--dark-rgb), 0.1);
}

.deal-thumbnail {
    overflow: hidden;
    flex: 1 0 30%;
    max-width: 30%;
}

.thumbnail-wrapper {
    border-radius: 8px;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
}

.thumbnail-wrapper img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    transition: transform 0.2s ease-out;
}

.deal-summary {
    flex: 1 0 70%;
    max-width: 70%;
    padding-left: 1rem;
    color: #2b3649;
}

.deal-meta {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 12px;
}

.meta-info-tags {
    background: rgba(var(--dark-rgb), 0.1);
    color: var(--dark);
    padding: 2px 8px;
    border-radius: 20px;
    margin-right: 4px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.meta-info-tags.primary {
    background: rgba(var(--primary-rgb), 0.1);
    color: var(--primary);
}

.meta-info-tags.success {
    background: rgba(var(--success-rgb), 0.1);
    color: var(--success);
}

.deal-status {
    margin-left: auto;
}

.deal-meta a {
    color: var(--dark);
}

.deal-title {
    font-size: 18px;
    margin: 0 0 10px;
}

.deal-item:hover .deal-thumbnail img {
    transition: transform 5s ease;
    transform: scale(1.02);
}

.deal-item:hover .meta-info-tags {
    background-color: var(--dark);
    color: #fff;
}

.deal-item:hover .meta-info-tags.primary {
    background-color: var(--primary);
    color: #fff;
}

.deal-item:hover .meta-info-tags.success {
    background-color: var(--success);
    color: #fff;
}

.deal-item:hover .deal-title {
    color: var(--primary)
}

.deal-sponsor {
    font-size: 14px;
    font-weight: normal;
    margin: 0 0 10px;
}

.deal-highlists {
    margin: 15px -15px 0;
    display: flex;
    flex-flow: row wrap;
    border-top: 1px dashed #ddd;
    max-width: 560px;
}

.deal-highlight-count {
    flex: 1 0 50%;
    max-width: 50%;
    padding: 10px 16px 0;
}

.deal-highlight-count .count {
    display: block;
    /* padding-left: 4px; */
}

.deal-highlight-count>span {
    color: var(--medium);
}

.breadcrumb-wrapper {
    margin-bottom: 16px;
}

.deal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.deal-title h1 {
    font-size: 24px;
    margin: 0 0 8px
}

.deal-content {
    display: flex;
    flex-wrap: wrap;
}

.deal-content-left {
    flex: 1;
    max-width: 100%;
}

.deal-content-right {
    margin: 0 0 0 16px;
    flex: 1 0 280px;
    max-width: 280px;
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    position: relative;
    border: 1px solid rgba(var(--dark-rgb), 0.2);
}

.deal-img {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}

.deal-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    object-fit: cover;
    top: 0;
}

.deal-content .deal-highlists {
    display: block;
    padding: 0;
    border: 0;
}

.deal-content .deal-highlight-count {
    margin: 0 0 8px;
    flex: 1 0 100%;
    max-width: 100%;
}

.deal-content .deal-sponsor {
    margin: 0 0 48px;
}

.deal-content .deal-action {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.deal-action .btn {
    width: 100%;
    border-radius: 0 0 12px 12px;
}

.deal-widget {
    margin: 16px 0;
    width: 100%;
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    border: 1px solid rgba(var(--dark-rgb), 0.2);
}

.deal-widget-title {
    margin: 0 0 16px;
}

.nav-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--light);
    margin: 1rem 0;
}

.nav-tabs .tab-item {
    padding: 0 8px;
}

.tab-item a {
    padding: 8px 0;
    display: block;
    color: var(--dark);
    border: 0;
}

.tab-item a:hover {
    box-shadow: 0 1px 0 var(--dark);
}

.tab-item.active a {
    font-weight: bold;
    color: var(--primary);
    box-shadow: 0 1px 0 var(--primary);
}

.popover-wrapper {
    position: relative;
}

.popover {
    position: absolute;
    top: 100%;
    right: 0;
    /* display: none; */
    width: 280px;
    background: #fff;
    border-radius: 12px;
    border: 1px solid rgba(var(--dark-rgb), 0.5);
    box-shadow: 0 3px 6px rgba(var(--dark-rgb), 0.2);
    z-index: 99;
}

/* .popover-wrapper:hover .popover {
    display: block;
} */

.popover-wrapper>button {
    margin-bottom: 10px;
}

.popover:after {
    content: "";
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    right: 20px;
    z-index: 2;
}

.popover:before {
    content: "";
    border-bottom: 10px solid rgba(var(--dark-rgb), 0.5);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -11px;
    right: 20px;
    z-index: 2;
}

.popover-content {
    padding: 16px;
}

/* 
.banner {
    position: relative;
    min-height: 60vh;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
}

.banner-image:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 140%;
    height: 150px;
    border-radius: 50%;
    bottom: -125px;
    left: -20%;
    background: #fff;
}

.banner-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    margin: auto;
    right: 0;
    max-width: 1280px;
}

.banner-image>img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
} */

.swiper-container {
    margin: auto;
    max-width: 1280px;
}

.swiper-container:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 140%;
    height: 150px;
    border-radius: 50%;
    bottom: -125px;
    left: -20%;
    background: #fff;
}

.swiper-slide {
    position: relative;
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
}

.banner-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    margin: auto;
    right: 0;
    max-width: 1280px;
}

.banner-image>img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
}

.banner .banner-content {
    max-width: 760px;
}

.banner h1 {
    margin: 0;
    color: #fff;
    font-size: 48px;
}

.form-cta {
    width: 100%;
    max-width: 360px;
    margin: 2rem auto;
    display: flex;
    background: #fff;
    border-radius: 4px;
}

.form-cta input {
    width: 100%;
    height: 40px;
    border-radius: 4px 0 0 4px;
    border: 1px solid #aaa;
    flex: 1 1 100%;
    max-width: 100%;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 8px 16px;
}

.form-cta>.btn {
    border-radius: 0 4px 4px 0;
    width: auto;
    flex: 1 0 auto;
    min-height: 40px;
}

.our-focus {
    padding: 60px 0;
}

.our-focus h2 {
    font-size: 32px;
    margin-top: 0;
}

.portfolio-wrapper {
    display: flex;
    flex-flow: row;
    margin: 0 -8px;
}

.portfolio-col {
    flex: 1 1 50%;
    max-width: 50%;
    padding: 8px;
}

.portfolio-img {
    position: relative;
    margin-bottom: 16px;
    cursor: pointer;
    display: block;
    overflow: hidden;
}

.portfolio-img.square {
    height: 0;
    padding-bottom: 100%;
}

.portfolio-img.rectangle {
    height: 0;
    padding-bottom: 65%;
}

.portfolio-col:first-child .portfolio-img:first-child {
    border-top-left-radius: 24px;
}

.portfolio-col:first-child .portfolio-img:last-child {
    border-bottom-left-radius: 24px;
}

.portfolio-col:last-child .portfolio-img:first-child {
    border-top-right-radius: 24px;
}

.portfolio-col:last-child .portfolio-img:last-child {
    border-bottom-right-radius: 24px;
}

.portfolio-img img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    -webkit-transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
}

.portfolio-img:hover img {
    -webkit-transition: transform 5s ease;
    transition: transform 5s ease;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.portfolio-img .img-caption {
    position: relative;
    z-index: 1;
    padding: 0.75rem 1.5rem;
    background: rgba(255, 255, 255, .9);
    display: inline-flex;
    border-radius: 20px;
    font-weight: bold;
    margin: 1rem;
    color: var(--dark);
}

.portfolio-img .img-caption svg {
    opacity: 0;
    width: 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.portfolio-img:hover .img-caption svg {
    opacity: 1;
    width: 16px;
    margin-left: 16px;
}

.page-banner {
    padding: 30px 0;
    min-height: 300px;
    background: var(--light);
    position: relative;
    margin-top: -30px;
    overflow: hidden;
}

.page-banner .container {
    position: absolute;
    left: 0;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 10;
    padding: 30px 15px;
}

/* .page-banner .container>* {
    flex: 1 0 50%;
    max-width: 50%;
} */

.page-title h1 {
    font-size: 48px;
    margin: 0;
}

.page-title .subtitle {
    font-size: 18px;
}

.page-banner-image {
    padding: 0 30px;
    text-align: center;
}

.page-banner-image img {
    max-width: 100%;
    height: auto;
}

.page-banner-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    right: 0;
    margin: auto;
    text-align: center;
    z-index: 0;
}

.page-content {
    padding: 30px 0;
    font-size: 1rem;
}

.contact-content {
    display: flex;
    background-color: #fff;
    border: 1px solid rgba(var(--dark-rgb), 0.2);
    padding: 30px;
    border-radius: 12px;
    margin-top: -40px;
    z-index: 10;
    position: relative;
}

.contact-content>* {
    flex: 1 1 50%;
    max-width: 50%;
}

.contact-content .divider {
    flex: auto;
    margin: 0 30px;
    padding: 30px 0;
    position: relative;
}

.contact-content .divider>span {
    padding: 0px;
    line-height: 24px;
    width: 24px;
    height: 24px;
    display: block;
    text-align: center;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(var(--dark-rgb), 0.2);
}

.contact-content .divider:after {
    content: "";
    width: 1px;
    height: calc(100% + 60px);
    position: absolute;
    left: 50%;
    top: -30px;
    background: rgba(var(--dark-rgb), 0.2);
    z-index: -1;
}

.contact-details-row {
    padding: 24px 0px 24px 0;
}

.contact-details-row:not(:last-child) {
    border-bottom: 1px solid rgba(var(--dark-rgb), 0.2);
}

.contact-type-text,
.contact-type-text a {
    font-size: 18px;
    color: var(--dark);
}

.contact-details-row .contact-icon {
    padding-right: 24px;
}

/* Footer */
footer {
    background-color: var(--light);
    color: var(--dark);
    padding: 45px 0;
}

footer .container {
    display: flex;
    flex-wrap: wrap;
}

.footer-col {
    flex: 1;
    padding: 15px;
}

.footer-col:first-child {
    padding-left: 0;
}

.footer-col:last-child {
    padding-right: 0;
}

footer h4,
footer p {
    opacity: 0.7;
    margin-top: 0;
}

.footer-brand {
    margin-bottom: 24px;
}

.footer-links {
    display: flex;
    flex-direction: column;
}

footer a {
    padding: 4px 0;
    margin-bottom: 4px;
    color: var(--dark);
    opacity: 0.7;
    transition: opacity 0.2s ease;
}

.social-link {
    padding: 8px;
    display: inline-flex;
}

footer a:hover {
    opacity: 1;
}