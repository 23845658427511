@import "wordpress-style.css";

.card {
    border-radius: 16px;
    max-width: 480px;
    margin: 0 auto;
}

.card+.card {
    margin-top: 10px;
}

.auth-footer {
    max-width: 480px;
    margin: 0 auto;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 135px;
}

.auth-footer a {
    color: var(--medium);
    margin: 8px;
    display: inline-block;
    font-size: 12px;
}

.auth-card .card-content {
    padding: 2rem;
}

.field-error {
    color: #ff0404;
    margin: 3px 21px;
    font-size: 13px;
    text-align: center;
}

.indicate-interest-ratings {
    font-size: 20px;
    padding: 4px;
}



.dropdown {
    position: relative;
}
.dropdown > .nav-link {
    padding: 1rem;
    display: block;
}
.dropdown-menu {
    display: none;
    min-width: 200px;
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    padding: 8px 0;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1), 0 2px 4px rgba(0,0,0,0.2);
    transform: translateY(20px);
    transition: all 0.2s ease;
    z-index: 99;
}
.dropdown-menu .nav-link{
    display: block;
}
.dropdown-header {
    padding: 0.5rem 1rem;
    color: var(--dark);
}
.nav-link > .bi-caret-down-fill {
    margin-left: 5px;
    vertical-align: middle;
}
.dropdown:hover .dropdown-menu {
    display: block;
    transform: translateY(0);
}

button[type="button"].btn-invest-disabled:hover {
    border: 1px solid transparent;
    box-shadow: 0px 0px;
    background-color: var(--light);
    cursor: default;
}
.cta-form {
    margin: auto;
max-width: 1280px;
}
.form-cta {
    margin: 2rem auto 0 auto;
}
.ReactModal__Overlay {
    z-index: 999;
}
.ReactModal__Body--open{
    overflow: hidden;
}
.modal-close-btn {
    position: fixed;
    top: 0;
    right: 40px;
    border-radius: 0;
    background: #fff;
    padding: 8px;
    z-index: 1000;
}
.ReactModal__Content {
    padding: 0 !important;
}